import styled from 'styled-components';

import { TextB } from 'styled';

export const Button = styled.button<any>`
  text-transform: uppercase;
  font-size: 0.9em;
	font-weight: ${props => props.$bold ? 'bold' : 'normal'};
  padding: 0.75em 1em;
  border: none;
  background: ${(props) => !props.$noStatus && props.disabled ? 'grey' : props.$alternate ? '#73B5BC' : 
	  props.$bgColor ? props.$bgColor : 'var(--color-button)'};
  color: white;
  width: ${props => props.$width || '100%'};
  margin-top: ${props => props.$noPadding ? '0' : '2em'};
  border-radius: 0.5em;
  cursor: pointer;
	height:${props => props.$height || ''};
  :active {
    background: var(--color-button-active);
  }
`;
