import { createSlice, current } from '@reduxjs/toolkit';

import { fetchProfile, fetchStatus, destroy } from 'features/ProfilePage/redux';
import { RootState } from 'app/store';
import { ThunkArgs, IResponse, Credentials, Profile, AuthResponse } from 'types';

export const hot = '../features/login/redux.ts';

export interface LoginState {
  loggedIn: boolean;
  status: 'idle' | 'loading' | 'failed';
  error: any;
  response: AuthResponse;
  credentials: Credentials;
}

const initialState: LoginState = {
  loggedIn: false,
  status: 'idle',
  error: '',
  response: {} as AuthResponse,
  credentials: {} as Credentials
};

export const counterSlice = createSlice({
  name: 'login',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    login: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      // state.loggedIn = true;
      state.credentials = action.payload;
    },
    failure: (state, action) => {
      state.loggedIn = false;
      state.error = action.payload;
    },
    status: (state, action) => {
      state.status = action.payload;
    },
    loggedIn: (state) => {
      state.loggedIn = true;
    },
    logout: (state) => {
      state.loggedIn = false;
      state.credentials = {} as Credentials;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(incrementAsync.pending, (state) => {
  //       state.status = 'loading';
  //     })
  //     .addCase(incrementAsync.fulfilled, (state, action) => {
  //       state.status = 'idle';
  //       state.value += action.payload;
  //     });
  // },
});

export const { login, failure, status, loggedIn, logout } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.login.value)`
export const selectIsLoggedIn = (state: RootState) => state.login.loggedIn;

export const selectStatus = (state: RootState) => state.login.status;

export const selectError = (state: RootState) => state.login.error;

export const selectCredentials = (state: RootState) => state.login.credentials;

export const selectLoggedIn = (state: RootState) => state.login.loggedIn;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const asyncLogin = (username: string, password: string, VerifyToken: string) => async (
  dispatch: Function, _: void, { callEndpoint }: ThunkArgs
): Promise<IResponse> => {
  const response: IResponse = await dispatch(callEndpoint({ isUnAuthed: true, api: 'api/Authenticate/V1.0/Logon', method: 'POST', body: { username, password }, VerifyToken }));
  console.log(response);
  if (response.status !== 'error' && !response.errors) {
    const expires = +(new Date()) + (response.data.expiresIn * 1000);
    const creds: Credentials = { ...response.data, expires };
    dispatch(login(creds));
    localStorage.setItem('auth', JSON.stringify(creds));
    dispatch(fetchProfile());
    dispatch(expireCreds());
  }
  else dispatch(failure(response.errors));
  return response;
};

export const resendConfirmEmail = (email: string) => async (
  dispatch: Function, _: void, { callEndpoint }: ThunkArgs
): Promise<IResponse> => {
  const response: IResponse = await dispatch(callEndpoint({ isUnAuthed: true, api: 'api/Accounts/V1.0/ResendValidation', method: 'POST', type: 'text', body: { email } }));
  return response;
};

export const expireCreds = () => async (
  dispatch: Function, getState: Function
): Promise<void> => {
  const creds = selectCredentials(getState());
  // console.log(+(new Date()), creds.expires);
  if (+(new Date()) > creds.expires) dispatch(doLogout());
  else setTimeout(() => dispatch(expireCreds()), 1000 * 10);
};

export const doLogout = () => async (
  dispatch: Function, _: void
): Promise<void> => {
  dispatch(logout());
  dispatch(destroy());
  localStorage.setItem('auth', '');
};

export const init = () => async (
  dispatch: Function, _: void
): Promise<void> => {
  const data: string | null = localStorage.getItem('auth');
  if (data) {
    dispatch(login(JSON.parse(data)));
    dispatch(loggedIn());
    dispatch(fetchProfile());
    dispatch(expireCreds());
  }
};

export default counterSlice.reducer;
