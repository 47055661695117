import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { useAppSelector } from 'app/hooks';
import { selectCredentials } from 'features/login/redux';

import * as G from 'styled';

const Title1 = styled.span`
  color: ${props => props.color || 'var(--color-text-A)'};
`;

const Title2 = styled.span`
  color: ${props => props.color || G.TextB};
`;

const StyledLink = styled<any>(Link)`
  text-decoration: none;
  font-size: ${props => props.fontSize || '0.7em'};
  display: inline-block;
  margin-left: 0.5em;
`;

function Agriwards({ color, fontSize }: { color?: string, fontSize?: string; }) {
  const creds = useAppSelector(selectCredentials);

  return (
    <>
      <StyledLink fontSize={fontSize} to={creds?.token ? '/' : '/'}>
        <Title1 color={color}>AGRI</Title1>
        <Title2 color={color}>GATA</Title2>
      </StyledLink>
    </>
  );
}

export default Agriwards;
