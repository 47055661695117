import parse from 'date-fns/parse';

export const isValidEmail = (val: string) => {
  const isSamsungBrowser = navigator.userAgent.match(/SAMSUNG|Samsung|samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i);
  if (!isSamsungBrowser) {
    let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEmail.test(val);
  } else {
    const split = val.split('@');
    if (split.length !== 2) return false;
    if (split[1].indexOf('.') < 2) return false;
    return true;
  }
};

export const fdate = (s: string | number) => {
  const x = new Date(s);
  const d = `${x.getDate().toString().padStart(2, '0')}/${(x.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${x.getFullYear()}`;
  // console.log(s, x, d);
  return d;
};

export const fdate2 = (s: string | number) => {
  const x = new Date(s);
  const d = `${x.getFullYear()}-${(x.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${x.getDate().toString().padStart(2, '0')}`;
  // console.log(s, x, d);
  return d;
};

export const formatTransactionDate = (date: string) => {
  const date1 = new Date(date);
  return date1.toLocaleString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const convertUTCDateToLocalDate = (date: string) => {
  const localDate = new Date(`${date}Z`);
  return localDate;
};