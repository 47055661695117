import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
// import {
//   persistStore,
//   persistReducer,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import apiReducer, { init as initApi, callEndpoint, hot as apiHot } from 'api';
import loginReducer, { hot as loginHot, init as initAuth } from 'features/login/redux';
import profileReducer, { hot as profileHot } from 'features/ProfilePage/redux';
import transactionReducer , { hot as transactionHot }from 'features/Wallet/redux';
import bundleReducer , { hot as bundleHot }from 'features/Wallet/redux';

// const persistConfig = {
//   key: 'root',
//   version: 1,
//   storage,
//   stateReconciler: autoMergeLevel2
// };

const combined = combineReducers({	
  login: loginReducer,
  api: apiReducer,
  profile: profileReducer,
  transactions: transactionReducer,
  bundles: bundleReducer,
});

// const persistedReducer = persistReducer<any>(persistConfig, combined);

export const store = configureStore({
  reducer: combined,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { callEndpoint },
      },
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    }),
});

// add hot module replacement for reducers
if (process.env.NODE_ENV !== 'production' && module?.['hot']) {
  module['hot'].accept([
    loginHot,
    apiHot,
    profileHot,
	  transactionHot,
	  bundleHot
  ], () => store.replaceReducer(combined as any));
}

// export const persistor = persistStore(store);

// run initialisers
const p = [initApi, initAuth].map((init: any) => store.dispatch(init()));
Promise.all(p)
  .then(() => console.log('inits done.'))
  .catch((e) => console.log(`inits error: ${e}`));

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
