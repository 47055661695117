import styled from 'styled-components';

export const Container = styled.div<any>`
  width: ${props => props.$size === 'small' ? '1em' : props.$size === 'medium' ? '4em' : '7em'};
  height: ${props => props.$size === 'small' ? '1em' : props.$size === 'medium' ? '4em' : '7em'};
  margin: auto;
`;

export const Part = styled.img`
  width: 50%;
  height: 100%;
`;
