import { createSlice } from '@reduxjs/toolkit';

import { IResponse, IDispatch, IState, CallArgs } from 'types';
import { RootState } from 'app/store';
import { selectCredentials } from 'features/login/redux';
import { apiUrl } from './environments';

import 'isomorphic-fetch';

export const hot = '../api/index.ts';

export interface ApiState {
  appUrl?: string;
  endpoints: any;
  status: 'idle' | 'loading' | 'failed';
  error: string;
}

const initialState: ApiState = {
  appUrl: apiUrl,
  endpoints: {},
  status: 'idle',
  error: ''
};

export const counterSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    init: (state, action) => {
      // console.log('api init ***');
      // state.endpoints = getApiEndpoints(state.appUrl);
    },
    failure: (state, action) => {
      state.error = action.payload;
    },
    status: (state, action) => {
      state.status = action.payload;
    }
  }
});

export const { init, status, failure } = counterSlice.actions;

export const selectAppUrl = (state: RootState) => state.api.appUrl;

export const selectStatus = (state: RootState) => state.api.status;

const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const callEndpoint = ({ noStatus, api, method, body, type = 'json', token, VerifyToken, isUnAuthed }: CallArgs) => async (
  dispatch: IDispatch,
  getState: IState
): Promise<IResponse> => {
  const state = getState();
  const url = selectAppUrl(state);
  const creds = selectCredentials(state);
  if (!isUnAuthed && !creds?.token) {
    return { status: 'error', errors: ['No auth token'] };
  }
  const options: any = {
    method,
    // mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
      'Content-Encoding': 'gzip, deflate, br',
      'SecurityToken': creds?.token ? creds.token : token ? `${token}` : null,
      'VerifyToken': VerifyToken || null
    },
    body: body ? JSON.stringify(body) : null
  };
  // console.log('options :::', options);
  try {
 		if (!noStatus) dispatch(status('loading'));
    const response = await fetch(
      `${url}/${api}`,
      options
    );
	  console.log('Api response',response);
    let data: any;
    if (type === 'json') data = await response.json();
    else if (type === 'blob') {
      data = await response.blob();
    } else {
      data = await response.text();
    }

    if (!noStatus) dispatch(status('idle'));
    if (data?.errorCode) {
      return { status: 'error', errors: [data.description] };
    }
    if (data.errors) {
      const errors: string[] = [];
      Object.entries((_, value: string) => errors.push(value));
      return { status: 'error', errors };
    }
    const value: IResponse = { status: 'success', data };
    return value;
  } catch (e: any) {
    // console.log('api error ::::', e);
    if (e.message.indexOf('JSON input')) {
      dispatch(status('idle'));
      return { status: 'success', data: {}, errors: ['Malformatted response.'] };
    }
    dispatch(failure(e.message));
    if (!noStatus) dispatch(status('failed'));
    const value: IResponse = { status: 'error', errors: [e.message] };
    return value;
  }
};

export default counterSlice.reducer;
