import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import { selectStatus } from 'api';
import { useAppSelector } from 'app/hooks';
import * as S from './styled';

type Props = {
  messageId: string;
  onClick?: Function;
  width?: string;
  noPadding?: boolean;
  to?: string;
  noStatus?: boolean;
	disabled?: boolean;
  alternate?: boolean;
	bold?: boolean;
	height?: string;
	bgColor?:string;
}

function AsyncButton({ messageId, onClick, width, noPadding, noStatus, to, disabled, alternate, bold , height , bgColor }: Props) {
  const status = useAppSelector(selectStatus);
  const history = useHistory();

  const handleClick = () => {
    if (to) history.push(to);
    else if (onClick) onClick();
  };

  return (
    <S.Button $bold={bold} $alternate={alternate} $noPadding={noPadding} $width={width} $height={height} $bgColor={bgColor}
		  disabled={status === 'loading' || disabled} onClick={handleClick}>
      {!noStatus && status === 'loading' ? <FormattedMessage id='waiting' /> : <FormattedMessage id={messageId} />}
    </S.Button>
  );
}

export default AsyncButton;
