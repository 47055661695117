import styled from 'styled-components';
import Color from 'color';
import { Link } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

export const TextA = '#568F99';
export const TextB = '#39756A';

export const LinkColor = Color('blue');

export const ButtonColor = Color('#98995f');

export const DarkBGColor = Color('black');

export const BGColorDark = Color('grey').darken(0.4).toString();

export const 	SocialIconColor = Color('gold').darken(0.2).toString();

export const TextAL = Color(TextA).lighten(0.6).toString();

export const Padded = styled.span`
  display: inline-block;
  padding: 1em;
`;

export const FigmaStyle = createGlobalStyle`
  html {
    --color-text-B: #39756A;
		--color-text-A: #568F99;
		--color-light-border: rgba(86, 143, 153, 0.6);
		--color-social-icon: rgba(86, 143, 153, 0.6);
		--color-button-active: rgba(86, 143, 153, 1);
		--color-button: rgba(86, 143, 153, 0.8);
		--color-text-al: rgba(173, 204, 209, 1);
  }
`;

export const GoldButton = styled<any>(Link)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  width: ${(props) => props.$widthLarge || '35%'};
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  background: #CF9C00;
  border-radius: 4px;
  padding: 6px 20px;
  text-transform: uppercase;
  color: whitesmoke;
  background-color: ${SocialIconColor};
  border: none;
  ${props => props.$isblock ? 'margin: auto;' : ''}
  &:hover {
    background-color: ${Color(SocialIconColor).darken(0.2).toString()};
    cursor: pointer;
  }
  @media (max-width: 900px) {
    font-size: 1em;
    width: ${(props) => props.$widthSmall || '80%'};
  }
`;

export const aGoldButton = styled.a<any>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  width: ${(props) => props.$widthLarge || '35%'};
  font-size: 2em;
  padding: 0.5em;
  margin: 0.5em;
  border-radius: 2em;
  color: whitesmoke;
  background-color: ${SocialIconColor};
  border: none;
  &:hover {
    background-color: ${Color(SocialIconColor).darken(0.2).toString()};
    cursor: pointer;
  }
  @media (max-width: 900px) {
    font-size: 1em;
    width: ${(props) => props.$widthSmall || '80%'};
    margin: 0.5em auto;
  }
`;

export const ContentContainer = styled.div`
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  max-width: 900px;
`;

export const MainHeader = styled.h1`
//styleName: H1;
font-family: Open Sans;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 44px;
letter-spacing: 0em;
text-align: center;
color: #286A5E;
`;

export const Paragraph = styled.p`
//styleName: Paragraph;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
color: #424B5A;
max-width: 720px;
margin: auto;
`;
