import EtFlag from 'assets/flagge-aethiopien.png';
import AuFlag from 'assets/au_flag.png';

export const flags = {
  Australia: AuFlag,
  Etheopia: EtFlag
};

export enum PROFILE_STATUS {
  None = 0,
  Created = 1,
  KYCInProgress = 2,
  KYCFailed = 3,
  KYCPassed = 4,
  Ready = 5,
  AccountConfirming = 6,
  AccountConfirmed = 7,
  KYCManual = 8
}

export const COUNTRIES = new Map();
COUNTRIES.set(1, 'USA');
COUNTRIES.set(2, 'AUS');

export const govIdRequired = ['BR', 'CA', 'CN', 'EG', 'ES', 'HK', 'IN', 'IT', 'JO', 'JP', 'KW', 'MX', 'MY', 'OM', 'RO', 'RU', 'SA', 'SG', 'TR', 'US', 'ZA'];