import { FormattedMessage } from 'react-intl';
import { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import styled from 'styled-components';

import { doLogout } from '../login/redux';
import { FigmaStyle } from 'styled';
import { Profile as ProfileType } from 'types';
import { PROFILE_STATUS } from 'appConstants';
import { selectLoggedIn } from '../login/redux';
import {
  selectProfile,
  selectprofileState,
  selectstatusState,
  selectStatus,
} from 'features/ProfilePage/redux';

import { LanguageContext } from 'components/Language/Context';
import { ThemeContext } from 'components/Theme/Context';
import { useAppSelector } from 'app/hooks';
import * as S from './styled';
import Header from 'components/shared/Header';
import TermsConditions from 'features/SignUp/components/Terms';

import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';

const BuyBundle = lazy(() => import('../BuyBundle'));
const HomePage = lazy(() => import('../HomePage'));
const Login = lazy(() => import('../login'));
const Profile = lazy(() => import('../ProfilePage'));
const ResetPassword = lazy(() => import('../ResetPassword'));
const SignUp = lazy(() => import('../SignUp'));
const ThankYou = lazy(() => import('../ThankYou'));
const ChangePassword = lazy(() => import('../ChangePassword'));
const Wallet = lazy(() => import('../Wallet'));
const RetryKYC = lazy(() => import('../RetryKYC'));
const ContactUs = lazy(() => import('../ContactUs'));
const ConfirmAccount = lazy(() => import('../ConfirmAccount'));
const RegistrationComplete = lazy(() => import('../RegistrationComplete'));
const ContactForm = lazy(() => import('../ContactForm'));
const Faq = lazy(() => import('../Faq'));
const Users = lazy(() => import('../Users'));
const Admin = lazy(() => import('../Admin'));
const EOIForm = lazy(() => import('../EOI'));
const SoldBundles = lazy(() => import('../SoldBundles'));
const BundleReservations = lazy(() => import('../BundleReservations'));
const EOIList = lazy(() => import('../EOIList'));
const CreateBundle = lazy(() => import('../CreateBundle'));
const AssignableBundles = lazy(() => import('../AssignableBundles'));
const Stripe = lazy(() => import('../Payments/screens/Stripe'));
const ChosePayment = lazy(() => import('../Payments/screens/ChosePayment'));
const BankTransferComplete = lazy(() => import('../Payments/screens/bankTransferComplete'));
const PaymentContactUs = lazy(() => import('../Payments/screens/PaymentContactUs'));
const BankTransfer = lazy(() => import('../Payments/screens/BankTransfer'));
const ConfirmCancelReservation = lazy(() => import('../BundleReservations/screens/ConfirmCancelReservation'));
const ReservationCancelled = lazy(() => import('../BundleReservations/screens/ReservationCancelled'));
const PaymentChange = lazy(() => import('../Payments/screens/PaymentChange'));
const EOIComplete = lazy(() => import('../EOI/screens/Complete'));

const NotFound = styled.div`
  width: 100%;
  padding-top: 4em;
  text-align: center;
`;

const NoRoute = () => (
  <>
    <Header />
    <NotFound>
      <FormattedMessage id='noPage' />
    </NotFound>
  </>
);

const authedRoutes = ['wallet', 'profile', 'thankyou', 'buybundle', 'retryKyc', 'contactUs', 'changePassword', 'changepassword', 'confirmCancelReservation', 'editprofile',
  'contactForm', 'admin', 'users', 'soldBundles', 'bundleReservations', 'EOIList', 'faq', 'terms', 'createBundle', 'assignableBundles', 'reservationCancelled',
  'stripe', 'payment', 'paymentContactUs', 'chosePayment', 'BankTransferComplete', 'bankTransfer', 'paymentChange', 'EOIComplete', ''];
const unauthedRoutes = ['resetPassword', 'signup', 'login', 'thankyou', 'changePassword', 'changepassword', 'confirmaccount', 'terms',
  'registrationComplete', 'contactForm', 'resendConfirmation', 'expression-of-interest-form', 'faq', ''];

function MyRedirect({
  loggedIn,
  profile,
}: {
  loggedIn: boolean;
  profile: ProfileType;
}) {
  const [redirect, setRedirect] = useState<any>({ path: null });
  const status = useAppSelector(selectStatus);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (loggedIn && !PROFILE_STATUS[status || '']) {
      history.push('/');
      setTimeout(() => dispatch(doLogout()), 1);
    }
  }, [status, dispatch, history, loggedIn, location.pathname]);

  // useEffect(() => {
  //   if (profile?.permissions?.find((perm) => perm === 100)) return;
  //   if (loggedIn && PROFILE_STATUS.Ready === status) {
  //     history.push('/buybundle');
  //   }
  //   /* eslint-disable-next-line */
  // }, []);

  useEffect(() => {
    const split = location.pathname.split('/');
    console.log('split :::', split);
    const slug = split[split.length - 1];
    const slug2 = split[1];
    if (slug2 === 'stripe') return;
    if (loggedIn && status === PROFILE_STATUS.KYCFailed && ['/retryKyc', '/contactUs'].indexOf(location.pathname) < 0) return setRedirect({ path: '/retryKyc' });
    if (slug && (loggedIn && !authedRoutes.find(route => route === slug))) return setRedirect({ path: '/404' });
    if (slug && (!loggedIn && !unauthedRoutes.find(route => route === slug))) return setRedirect({ path: '/' });
	  if (slug == 'admin' && !profile.permissions.find((perm)=>perm==100)) return setRedirect({ path: '/' });
	  if (slug == 'users' && !profile.permissions.find((perm)=>perm==100)) return setRedirect({ path: '/' });
	  if (slug == 'soldBundles' && !profile.permissions.find((perm)=>perm==104)) return setRedirect({ path: '/' });
	  if (slug == 'bundleReservations' && !profile.permissions.find((perm)=>perm==102)) return setRedirect({ path: '/' });
	  if (slug == 'createBundle' && !profile.permissions.find((perm)=>perm==102)) return setRedirect({ path: '/' });
	  if (slug === 'EOIList' && !profile.permissions.find((perm)=>perm===100)) return setRedirect({ path: '/' });
  }, [loggedIn, location.pathname, setRedirect, profile, status]);

  useEffect(() => {
    redirect.path !== null && history.push(redirect.path);
  }, [redirect, history]);

  return redirect.path !== null ? <Redirect to={redirect} /> : null;
}

export const Loader = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <S.Container theme={theme}>
      <S.Loader>
        <FormattedMessage id='loading' />
        ...
      </S.Loader>
    </S.Container>
  );
};

export const Terms = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <S.Container theme={theme}>
      <Header />
      <TermsConditions privacy />
    </S.Container>
  );
};

export default function App() {
  const loggedIn = useAppSelector(selectLoggedIn);
  const { theme } = useContext(ThemeContext);
  const profileState = useAppSelector(selectprofileState);
  const statusState = useAppSelector(selectstatusState);
  const profile = useAppSelector(selectProfile);
  const status = useAppSelector(selectStatus);
  const { messages } = useContext(LanguageContext);

  if (loggedIn && (profileState !== 'done' || statusState !== 'done'))
    return <Loader />;

  return (

    <Router>
      <FigmaStyle />
      <div>
        <Suspense fallback={<Loader />}>
          <Switch>
            {loggedIn ? (
              <>
                <MyRedirect
                  loggedIn={loggedIn}
                  profile={profile || ({} as ProfileType)}
                />
                <Route exact path='/assignableBundles'>
                  <AssignableBundles />
                </Route>
                <Route exact path='/createBundle'>
                  <CreateBundle />
                </Route>
                <Route exact path='/EOIList'>
                  <EOIList />
                </Route>
                <Route exact path='/EOIComplete'>
                  <EOIComplete messages={messages} />
                </Route>
                <Route exact path='/soldBundles'>
                  <SoldBundles />
                </Route>
                <Route exact path='/bundleReservations'>
                  <BundleReservations />
                </Route>
                <Route exact path='/admin'>
                  <Admin />
                </Route>
                <Route exact path='/users'>
                  <Users />
                </Route>
                <Route exact path='/wallet'>
                  <Wallet />
                </Route>
                <Route exact path='/profile'>
                  <Profile />
                </Route>
                <Route exact path='/buybundle'>
                  <BuyBundle />
                </Route>
                <Route exact path='/changePassword'>
                  <ChangePassword />
                </Route>
                <Route exact path='/editprofile'>
                  <RetryKYC isEditProfile />
                </Route>
                <Route exact path='/contactForm'>
                  <ContactForm />
                </Route>
                <Route exact path='/contactUs'>
                  <ContactUs />
                </Route>
                <Route exact path='/retryKyc'>
                  <RetryKYC />
                </Route>
                <Route exact path='/faq'>
                  <Faq />
                </Route>
                <Route exact path='/terms'>
                  <Terms />
                </Route>
                <Route exact path='/confirmCancelReservation'>
                  <ConfirmCancelReservation />
                </Route>
                <Route exact path='/chosePayment'>
                  <ChosePayment />
                </Route>
                <Route exact path='/bankTransfer'>
                  <BankTransfer />
                </Route>
                <Route exact path='/BankTransferComplete'>
                  <BankTransferComplete />
                </Route>
                <Route exact path='/paymentChange'>
                  <PaymentChange />
                </Route>
                <Route exact path='/stripe/:status/:payRef/:reservationId'>
                  <Stripe />
                </Route>
                <Route exact path='/stripe/:status'>
                  <Stripe />
                </Route>
                <Route exact path='/paymentContactUs'>
                  <PaymentContactUs />
                </Route>
                <Route exact path='/reservationCancelled'>
                  <ReservationCancelled />
                </Route>
                <Route exact path='/'>
                  <Wallet />
                </Route>
                <Route exact path='/404'>
                  <NoRoute />
                </Route>
                <MyRedirect
                  loggedIn={loggedIn}
                  profile={profile || ({} as ProfileType)}
                />
              </>
            ) : (
              <>
                <MyRedirect
                  loggedIn={loggedIn}
                  profile={profile || ({} as ProfileType)}
                />
                <Route exact path='/resendConfirmation'>
                  <Login goToResend={true} />
                </Route>
                <Route exact path='/contactForm'>
                  <ContactForm />
                </Route>
                <Route exact path='/registrationComplete'>
                  <RegistrationComplete />
                </Route>
                <Route exact path='/thankyou'>
                  <ThankYou />
                </Route>
                <Route exact path='/confirmaccount'>
                  <ConfirmAccount />
                </Route>
                <Route exact path='/changepassword'>
                  <ChangePassword />
                </Route>
                <Route exact path='/resetPassword'>
                  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
                    <ResetPassword />
                  </GoogleReCaptchaProvider>
                </Route>
                <Route exact path='/signup'>
                  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
                    <SignUp />
                  </GoogleReCaptchaProvider>
                </Route>
                <Route exact path='/login'>
                  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
                    <Login />
                  </GoogleReCaptchaProvider>
                </Route>
                <Route exact path='/terms'>
                  <Terms />
                </Route>
                <Route exact path='/'>
                  <HomePage />
                </Route>
                {/* <Route exact path='/'>
 									<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
                  	<Login />
 									</GoogleReCaptchaProvider>
                </Route> */}
                <Route exact path='/expression-of-interest-form'>
                  <EOIForm />
                </Route>
                <Route exact path='/faq'>
                  <Faq />
                </Route>
                <Route exact path='/404'>
                  <NoRoute />
                </Route>
              </>
            )}
          </Switch>
        </Suspense>
      </div>
    </Router>
  );
}
