import { createSlice } from '@reduxjs/toolkit';

import { ThunkArgs, IResponse, Transaction, Bundle, BundleDisplay } from 'types';
import { RootState } from 'app/store';
export const hot = '../features/Wallet/redux.ts';
import { convertUTCDateToLocalDate } from 'utils';

export interface TransactionState {
  transactions: Transaction[];
  transaction: Transaction | null;
	bundles: BundleDisplay[];
	bundle: BundleDisplay | null;
  balance: number;
  totalTokens: number;
  totalUnlocked: number;
	group_transactions: Transaction[];
	selectedTransId:number[];
}

const initialState: TransactionState = {
  transactions: [],
  transaction: null,
 	bundles: [],
 	bundle: null,
  balance: 0,
  totalTokens: 0,
  totalUnlocked: 0,
  group_transactions: [],
  selectedTransId: []
};

export const counterSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    transactions: (state, action) => {
      state.transactions = action.payload;
    },
	  bundles: (state, action) => {
      state.bundles = action.payload;
    },
	  balance: (state, action) => {
      state.balance = action.payload;
    },
	  totalTokens: (state, action) => {
      state.totalTokens = action.payload;
    },
	  totalUnlocked: (state, action) => {
      state.totalUnlocked = action.payload;
    },
	  group_transactions: (state, action) => {
      state.group_transactions = action.payload;
    },
	  selectedTransId: (state, action) => {
      state.selectedTransId = action.payload;
    },
  },
});

export const { transactions, bundles, balance, totalTokens, totalUnlocked, group_transactions, selectedTransId } =  counterSlice.actions;

export const selectTransactions = (state: RootState) => state.transactions.transactions;
export const selectBundles = (state: RootState) => state.transactions.bundles;
export const selectBalance = (state: RootState) => state.transactions.balance;
export const selectTotalUnlocked = (state: RootState) => state.transactions.totalUnlocked;
export const selectTotalTokens = (state: RootState) => state.transactions.totalTokens;
export const selectGroup_transactions = (state: RootState) => state.transactions.group_transactions;
export const selectSelectedTransId = (state: RootState) => state.transactions.selectedTransId;

export const setSelectedTransId = (transId) => async (
  dispatch: Function, _: void
): Promise<void> => {
  dispatch(selectedTransId(transId));
};

export const setGroup_transactions = (transactions) => async (
  dispatch: Function, _: void
): Promise<void> => {
  dispatch(group_transactions(transactions));
};

export const getBalance = () => async (
  dispatch: Function, _: void, { callEndpoint }: ThunkArgs
): Promise<IResponse> => {
	 const result: IResponse = await dispatch(callEndpoint({ api: 'api/Wallet/V1.0/Balances', method: 'GET' }));
  if (result.status === 'success') {
    dispatch(balance(result.data?.reduce((acc, curr) => acc + curr.totalAvailable, 0)));
    dispatch(totalTokens(result.data?.reduce((acc, curr) => acc + curr.totalTokens, 0)));
    dispatch(totalUnlocked(result.data?.reduce((acc, curr) => acc + curr.totalUnlocked, 0)));
  }
  return result;
};

export const getTransactions = () => async (
  dispatch: Function, _: void, { callEndpoint }: ThunkArgs
): Promise<void> => {
	 const result: IResponse = await dispatch(callEndpoint({ api: 'api/Transactions/V1.0/Items', method: 'GET' }));
  if (result.status === 'success') {
 		console.log(JSON.stringify(result.data));
    dispatch(transactions(result.data));
  }
};

export const getGroupTransactions = ( transId ) => async (
  dispatch: Function, _: void, { callEndpoint }: ThunkArgs
): Promise<any> => {
	 const result: IResponse = await dispatch(callEndpoint({ api: 'api/Transactions/V1.0/Group/'+transId+'/Items', method: 'GET' }));
  if (result.status === 'success') {
 		console.log(JSON.stringify(result.data));
	  return result.data;
  }
  return [];
};

export const getBundles = () => async (
  dispatch: Function, _: void, { callEndpoint }: ThunkArgs
): Promise<void> => {
  const response: IResponse = await dispatch(callEndpoint({ api: 'api/Transactions/V1.0/Bundles', method: 'GET' }));
  console.log('Got Bundle Response ',response);
  if (response.status === 'success') {
	  const bundlesByDate = groupBundlesByDate(response.data);
	  dispatch(bundles(bundlesByDate));
  }
};

const groupBundlesByDate = (bundles: Bundle[]): BundleDisplay[]=> {
 	const bundleList: BundleDisplay[] = [];
 	const options: any = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

 	if(!bundles) {
 		return bundleList;
 	}

 	bundles.map((t) => {
	  var date = convertUTCDateToLocalDate(t.updatedAsOf);
	 	let bundleDate = new Date(date).toLocaleDateString('en', options);
	 	const today = new Date().toLocaleDateString('en', options);
	 	bundleDate = today === bundleDate ? 'Today' : bundleDate;

	 	const bundleFound = bundleList.findIndex(bu => bu.date === bundleDate);
	 	if(bundleFound > -1){
		 	bundleList[bundleFound].bundles.push(t);
	 	} else {
	 		const bundleDisplay: BundleDisplay = { date: bundleDate, bundles: [t] };
		 	bundleList.push(bundleDisplay);
	 	}
	 	return bundleList;
 	});

 	return bundleList;
};

export default counterSlice.reducer;