import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import Color from 'color';
import { PROFILE_STATUS } from 'appConstants';

export const HeaderContainer = styled.div`
  width: 100%;
  padding: 1em;
  display: table;
  /* display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center; */
  margin: auto;
  border-bottom: 1px solid #286A5E;
`;

export const HeaderImage = styled.img`
  width: ${props => props.width || '2em'};
  height: auto;
  margin-right: 1em;
  @media(max-width: 900px) {
    margin-right: 0.5em;
  }
`;

export const ImageSection = styled.div`
  padding-left: 1em;
  display: flex;
  align-content: space-between;
  align-items: center;
  font-size: 1.8em;
  /* align-self: center;
  place-self: start; */
  float: left;
  @media(max-width: 900px) {
    padding-left: 0.2em;
  }
`;

export const LinksSection = styled.div`
  padding: 0 2em;
  height: 2em;
  display: flex;
  align-content: space-between;
  align-items: center;
  /* align-self: center;
  place-self: end; */
  float: right;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px;
letter-spacing: 0px;
text-align: left;

`;

export const Link = styled<any>(RouterLink)`
  font-size: 0.9em;
  margin-right: ${props => props.$isblock ? '0' : '3em'};
  text-transform: uppercase;
  text-decoration: none;
  color: ${props => props.$active ? '#497983' : 'black'};
  display: ${props => props.$isblock ? 'block' : 'inline'};
  padding-bottom: ${props => props.$isblock ? '1.5em' : '0'};
  white-space: nowrap;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  ${props => props.$active ? 'border-bottom: 1px solid #497983;' : ''}
`;

export const aLink = styled.a<any>`
  font-size: 0.9em;
  margin-right: ${props => props.$isblock ? '0' : '3em'};
  text-transform: uppercase;
  text-decoration: none;
  color: black;
  display: block;
  padding-bottom: ${props => props.$isblock ? '1.5em' : '0'};
  white-space: nowrap;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;

`;

export const Pipe = styled.div`
  font-size: 0.9em;
  padding: 0 0 0 1em;
  text-transform: uppercase;
`;

export const Divider = styled.div`
  font-size: 0.9em;
  padding-left: 3em;
  text-transform: uppercase;
`;

export const MobileHeaderContainer = styled.div`
  padding: 1em;
  height: 4em;
  width: 100vw;
  z-index: 99;
  color: white;
  font-size: 1em;
  display: block;
  border-bottom: 1px solid #286A5E;
`;

export const MobileImageSection = styled.div`
  padding-left: 1em;
  position: relative;
  height: 1.5em;
  width: 100%;
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.8em;
  /* align-self: center;
  place-self: start; */
  float: left;
  & > span {
    width: 4em;
    display: flex;
    align-items: center;
  }
  @media(max-width: 900px) {
    padding-left: 0.2em;
  }
`;

export const MenuClose = styled.div<any>`
  position: absolute;
  right: 0.2em;
  top: 0em;
`;

export const MobileLinksContainer = styled.div<any>`
  position: relative;
  padding-top: 4em;
  width: 100%;
  height: 100vh;
`;

export const MobileLinksLoggedOut = styled.div<any>`
  position: absolute;
  top: 40%;
  transform: translate(0, -50%);
  margin-top: 4em;
  width: 100%;
  text-align: center;
  a {
    margin-top: 1em;
    margin-bottom: 1em;
  }
`;

export const MobileLinksLoggedIn= styled.div<any>`
  margin-top: 2em;
  width: 100%;
  text-align: center;
  a {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
`;

export const LogoutMobile = styled.div`
  position: absolute;
  bottom: 4em;
  left: 50%;
  transform: translate(-50%, 0);
`;

export const BarsMenu = styled.div<any>`
  float: right;
  color: ${props => props.$isDark ? 'var(--color-text-A)' : 'white'};
  font-size: 1.75em;
  float: right;
  :active {
    color: ${Color('white').darken(0.2).toString()}
  }
`;

export const MenuPanel = styled.div<any>`
  padding: 0.5em;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  right: ${props => props.right};
  text-align: left;
  transition: right ease 300ms;
  background-color: ${Color('white').darken(0.05).toString()};
  z-index: 999;
`;

const colors = {
  [PROFILE_STATUS.Created]: 'lightblue',
  [PROFILE_STATUS.Ready]: '#134F5C',
  [PROFILE_STATUS.KYCFailed]: '#FFC5C5',
  [PROFILE_STATUS.KYCInProgress]: '#3694cf',
  [PROFILE_STATUS.KYCPassed]: 'lightgreen',
  [PROFILE_STATUS.KYCManual]: 'lightblue',
  [PROFILE_STATUS.AccountConfirmed]: 'lightblue',
  'default': 'red'
};

export const StatusBar = styled.div<any>`
  width: 100%;
  padding: 1em 20%;
  background-color: ${props => colors[props.$status || 'default']};
  color: white;
  text-align: center;
`;

export const LinkButtonDiv = styled.div`
  padding: 0.1em;
  background-color: #134F5C;
	width:5em;
	display:inline;
`;
