import styled from 'styled-components';

export const Padded = styled.div`
  margin: 2em;
  margin: 2em auto;
  max-width: 50em;
  padding-bottom: 2em;
  @media(max-width: 900px) {
    margin: auto;
    width: 90%;
  }
`;

export const Container = styled.div`
	text-align: center;
	padding: 2em;
  margin: 0;
  border: 1px solid lightgrey;
  border-radius: 1em;
  width: 100%;
  margin-bottom: 1.5em;
  @media(max-width: 900px) {
    padding: 0;
    overflow: hidden;
  }
`;

export const Buttons = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;

export const IFrame = styled.iframe`
  width: 100%;
  height: 60vh;
  border: none;
  @media(max-width: 900px) {
    height: 40em;
  }
`;

export const AgreeText = styled.div`
  padding-top: 1.5em;
  width: 100%;
  font-size: 0.8em;
  color: #5B5B5B;
  text-align: center;
`;
