import { FormattedMessage } from 'react-intl';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { LanguageContext } from 'components/Language/Context';
import { State } from './SignUpForm';
import * as S from './Terms.styled';
import AsyncButton from 'components/AsyncButton';
import LinkButton from 'components/LinkButton';
import Modal from './Modal';

function Terms({ state, set, setPage, privacy }: { state?: State, set?: Function, setPage?: Function, privacy?: boolean }){
  const { messages } = useContext(LanguageContext);
  const history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    document.getElementById('header-top')?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return(
	  <S.Padded>
		  {showModal && ( <Modal set={set} setPage={setPage} setShowModal={setShowModal} /> ) }
		  <S.Container>
        <S.IFrame src={privacy ? '/AgrigataPrivacyPolicy.html' : '/AgriUTTokenSaleAgreementFinalv3.0.html'} />
      </S.Container>
      <S.Buttons>
        {setPage && <AsyncButton width='20em' noPadding messageId={'acceptTokenAgreement'} onClick={() => {
          setShowModal(true);
        }} />}
        <LinkButton onClick={() => setPage ? setPage(0) : history.goBack()} messageId='back' />
      </S.Buttons>
      {setPage && <S.AgreeText><FormattedMessage id='tokenAgreement' /></S.AgreeText>}
	  </S.Padded>
  );
};
export default Terms;