import { FormattedMessage } from 'react-intl';
import { useContext } from 'react';

import { LanguageContext } from 'components/Language/Context';
import * as S from './Modal.styled';

function Modal({ set, setPage, setShowModal }:{ set?: any, setPage: any, setShowModal: Function }){
  const { messages } = useContext(LanguageContext);
  return(
  	<>
		  <S.Background>
		  </S.Background>
		  <S.FormBackground>
			  <FormattedMessage id='areYouSure' /><br/>
				  <S.Button onClick={() => {
			  			set && set('termsChecked', null, 'checked');
			    		setPage && setPage(0);
			  		}}
			  	>{messages.yes.toUpperCase()}</S.Button>{' '}
		  		<S.Button2 onClick={() => setShowModal(false)}>{messages.no.toUpperCase()}</S.Button2>
		  </S.FormBackground>
  	</>
  );
}
export default Modal;