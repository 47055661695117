import { createSlice, current } from '@reduxjs/toolkit';

import { PROFILE_STATUS } from 'appConstants';
import { RootState } from 'app/store';
import { selectCredentials } from 'features/login/redux';
import { ThunkArgs, IResponse, Credentials, Profile } from 'types';

export const hot = '../features/login/redux.ts';

export interface ProfileState {
  profile: Profile | null;
  status: number | null;
  canRedoKYC?: boolean | null;
  error: string;
  balance: number | null;
  profileState: '' | 'done' | 'fetching' | 'error';
  statusState: '' | 'done' | 'fetching' | 'error';
}

const initialState: ProfileState = {
  profile: null,
  status: null,
  error: '',
  balance: null,
  canRedoKYC: false,
  profileState: '',
  statusState: ''
};

export const counterSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    profile: (state, action) => {
      state.profile = action.payload;
    },
    failure: (state, action) => {
      state.error = action.payload;
    },
    status: (state, action) => {
      state.status = action.payload;
    },
    canRedoKYC: (state, action) => {
      state.canRedoKYC = action.payload;
    },
    balance: (state, action) => {
      state.balance = action.payload;
    },
    statusState: (state, action) => {
      state.statusState = action.payload;
    },
    profileState: (state, action) => {
      state.profileState = action.payload;
    },
    destroy: (state) => {
      state.canRedoKYC = false;
      state.status = null;
      state.balance = null;
      state.profile = null;
      state.profileState = '';
      state.statusState = '';
    }
  },
});

export const { failure, status, profile, balance, destroy, canRedoKYC, profileState, statusState } = counterSlice.actions;

export const selectProfile = (state: RootState) => state.profile.profile;

export const selectStatus = (state: RootState) => state.profile.status;

export const selectBalance = (state: RootState) => state.profile.balance;

export const selectError = (state: RootState) => state.profile.error;

export const selectprofileState = (state: RootState) => state.profile.profileState;

export const selectstatusState = (state: RootState) => state.profile.statusState;

export const updateProfile = (data: Profile) => async (
  dispatch: Function, _: Function, { callEndpoint }: ThunkArgs
): Promise<IResponse> => {
  const response: IResponse = await dispatch(callEndpoint({ api: 'api/Accounts/V1.0/Profile', method: 'PATCH', body: data }));
  if (response.status !== 'error') {
    dispatch(fetchProfile());
  }
  else dispatch(failure(response.errors));
  return response;
};

const statusRefreshInterval = 1000 * 60;

export const fetchProfile = () => async (
  dispatch: Function, getState: Function, { callEndpoint }: ThunkArgs
): Promise<IResponse> => {
  const isDone = selectprofileState(getState()) === 'done';
  if (!isDone) dispatch(profileState('fetching'));
  const isStatusDone = selectstatusState(getState()) === 'done';
  if (!isStatusDone) dispatch(statusState('fetching'));
  const response: IResponse = await dispatch(callEndpoint({ api: 'api/Accounts/V1.0/Profile', method: 'GET' }));
  if (response.status !== 'error') {
    dispatch(profile(response.data));
    dispatch(status(response.data?.profileState));
    // dispatch(status(3));
    dispatch(canRedoKYC(response.data?.kycRetry === 1));
    if (response.data?.profileState !== PROFILE_STATUS.Ready) {
      setTimeout(() => dispatch(fetchStatus()), statusRefreshInterval);;
    }
  }
  else dispatch(failure(response.errors));
  if (!isDone) dispatch(profileState('done'));
  if (!isStatusDone) dispatch(statusState('done'));
  return response;
};

export const fetchStatus = () => async (
  dispatch: Function, getState: Function, { callEndpoint }: ThunkArgs
): Promise<IResponse> => {
  const creds = selectCredentials(getState());
  if (!creds?.token) return {};
  const response: IResponse = await dispatch(callEndpoint({ noStatus: true, api: 'api/Accounts/V1.0/Status', method: 'GET' }));
  if (response.status !== 'error') {
    dispatch(status(response.data.state));
    dispatch(canRedoKYC(response.data.canRedoKYC));
    if (response.data.state !== PROFILE_STATUS.Ready) {
      setTimeout(() => {
        const creds = selectCredentials(getState());
        if (!creds?.token) return {};
        dispatch(fetchStatus());
      }, statusRefreshInterval);;
    }
  }
  else dispatch(failure(response.errors));
  return response;
};

export default counterSlice.reducer;
