import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Background = styled.div`
	position:fixed;
	background-color: black;
	opacity: 0.5;
	width: 100%;
	height:100%;
	left:0;
	top:0;
`;

export const FormBackground = styled.div`
	position:fixed;
	background-color: white;
	//opacity: 0.5;
	padding:2em;
	width:30em;
	left:50%;
	top:50%;
	margin-left: -15em;
	margin-top: -10%;
  @media(max-width: 700px) {
		width: 90%;
  }
`;

export const Buttons = styled.div`
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
`;
export const Button = styled<any>(Link)`
	display: inline-block;
  align-items: center;
  text-decoration: none;
  text-align: center;
  width: 25%;
  font-size: 1em;
  padding: 0.3em;
	margin-top:1em;
	border-radius:0.2em;
	color:whitesmoke;
	background-color: #568F99;
	border:none;
	&:hover {
    background-color: grey;
		cursor: pointer;
  };
  @media(max-width: 900px) {
  	font-size: 1em;
		width: 45%;
  }
`;
export const Button2 = styled<any>(Link)`
  display: inline-block;
  align-items: center;
  text-decoration: none;
  text-align: center;
  width: 25%;
  font-size: 1em;
  padding: 0.3em;
	margin-top:1em;
	color:#568F99;
	background-color: whitesmoke;
	border:none;
	&:hover {
    background-color: lightgrey;
		cursor: pointer;
  };
  @media(max-width: 900px) {
  	font-size: 1em;
		width: 45%;
  }
`;