import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import MediaQuery from 'react-responsive';

import { agriwardsLink } from 'api/environments';
import { GoldButton } from 'styled';
import { PROFILE_STATUS } from 'appConstants';
import { selectIsLoggedIn, doLogout } from 'features/login/redux';
import { selectProfile, selectStatus as selectProfileStatus } from 'features/ProfilePage/redux';
import { ThemeContext } from 'components/Theme/Context';
import { useAppSelector } from 'app/hooks';
import * as S from './Header.styled';
import Agriwards from 'components/Agriwards';
import LinkButton from 'components/LinkButton';
import Logo from '../Logo';

function HomePage({ isDark = false }: { isDark?: boolean }) {
  const { setTheme } = useContext(ThemeContext);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showMoreDetails, setShowMoreDetails] = useState<boolean>(false);
  const [timedEvent, setTimedEvent] = useState<any>(null);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const myStatus = useAppSelector(selectProfileStatus);
  const profile = useAppSelector(selectProfile);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    function handleClick() {
      setShowMenu(false);
    }
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [setShowMenu]);

  useEffect(() => {
    setTheme('light');
  }, [setTheme]);

  const logout = () => {
    history.push('/');
    setTimeout(() => dispatch(doLogout()), 1);
  };

  const label = {
    [PROFILE_STATUS.Created]: 'Account Created',
    [PROFILE_STATUS.Ready]: 'Account Ready',
    [PROFILE_STATUS.KYCFailed]: 'KYC Failed',
    [PROFILE_STATUS.KYCInProgress]: 'KYC in Progress',
    [PROFILE_STATUS.KYCPassed]: 'KYC Passed',
    [PROFILE_STATUS.KYCManual]: 'KYC Manual',
    [PROFILE_STATUS.AccountConfirmed]: 'Account confirmed',
    'default': null
  };

  const showMoreDetails_ = function(){
	  if (timedEvent){
	    setShowMoreDetails(false);
		  clearTimeout(timedEvent);
		  setTimedEvent(null);
		  return;
	  }
	  setShowMoreDetails(true);
  	const removeMoreDetails = function(){
  		setShowMoreDetails(false);
		  setTimedEvent(null);
  	};
  	setTimedEvent(setTimeout(removeMoreDetails, 12000));
  };

  const status = label[myStatus || 'default'] ? (
	  <>
	  	<S.StatusBar $status={myStatus}>
	  		{label[myStatus || 'default']}&nbsp;
	  		{myStatus === PROFILE_STATUS.KYCFailed && !location.pathname.includes('retryKyc') ? <LinkButton messageId='retry' to='/retryKyc' /> : null}
	  		{myStatus === PROFILE_STATUS.KYCInProgress ?
	  			<>&nbsp;&nbsp;
					  <S.LinkButtonDiv>
					    <LinkButton messageId='more' onClick={()=>showMoreDetails_()} />
					  </S.LinkButtonDiv>
				  </> : null}
			  {showMoreDetails &&
					<>
					  <br/>
			  		<FormattedMessage id='thanksForInfo' />
			  		<br/><br/>
			  		<FormattedMessage id='theKYCIsUnderway' />
			  		<br/>
			  		<FormattedMessage id='emailUs' />
			  		<br/><br/>
					</>
			  }
	  	</S.StatusBar>
	  </>
  ) : null;

  return (
    <>
      <MediaQuery minWidth={700}>
        <>
          <S.HeaderContainer id='header-top'>
            <S.ImageSection>
              {/* <S.HeaderImage src={AgriutImage} /> */}
              <Logo size='small' />
              <Agriwards />
            </S.ImageSection>
            <S.LinksSection>
              {!isLoggedIn ? (
                <>
                  <S.aLink href={`${agriwardsLink}/reward`}><FormattedMessage id='reward' /></S.aLink>
                  <S.aLink target='_blank' href='/token-usage.html'><FormattedMessage id='tokenUsage' /></S.aLink>
                  <S.Link to='/login'><FormattedMessage id='login' /></S.Link>
                  <GoldButton to='/signup'><FormattedMessage id='signup' /></GoldButton>
                </>
              ) : (
                <>
                  <GoldButton to='/buybundle'><FormattedMessage id='getAgriut' /></GoldButton>
                  <div style={{ marginRight: '3em' }} />
                  <S.Link $active={location.pathname.includes('wallet') || location.pathname === '/'} to='/wallet'><FormattedMessage id='wallet' /></S.Link>
                  <S.Link $active={location.pathname.includes('profile')} to='/profile'><FormattedMessage id='seeProfile' /></S.Link>
								  { profile?.permissions.find((perm)=>perm==100) &&
										<>
										  <S.Link $active={location.pathname.includes('admin')} to='/admin' ><FormattedMessage id='admin' /></S.Link>
										</>
								  }
                  <S.Link onClick={logout} to='/'><FormattedMessage id='logout' /></S.Link>
                </>
              )}
            </S.LinksSection>
          </S.HeaderContainer>
          {myStatus !== null && status}
        </>
      </MediaQuery>
      <MediaQuery maxWidth={699}>
        <>
          <S.MobileHeaderContainer id='header-top'>
            <S.ImageSection>
              {/* <S.HeaderImage width='2em' src={AgriutImage} /> */}
              <Logo size='small' />
              <Agriwards />
            </S.ImageSection>
            <S.BarsMenu $isDark onClick={(e) => {
              e.stopPropagation();
              setShowMenu(!showMenu);
            }}>
              <FontAwesomeIcon icon={faBars} />
            </S.BarsMenu>
          </S.MobileHeaderContainer>
          {myStatus !== null && status}
        </>
      </MediaQuery>
      <S.MenuPanel right={showMenu ? '0' : '-100vw'}>
        <S.MobileImageSection>
          <span>
            {/* <S.HeaderImage width='2em' src={AgriutImage} /> */}
            <Logo size='small' />
            <Agriwards fontSize='16px' />
          </span>
          <S.MenuClose onClick={() => setShowMenu(false)}>
            <FontAwesomeIcon icon={faTimes} size='xs' color='grey' />
          </S.MenuClose>
        </S.MobileImageSection>
        <S.MobileLinksContainer>
          {!isLoggedIn ? (
            <S.MobileLinksLoggedOut>
              <S.aLink $isblock href={`${agriwardsLink}/reward`}><FormattedMessage id='reward' /></S.aLink>
              <S.aLink $isblock target='_blank' href='/token-usage.html'><FormattedMessage id='tokenUsage' /></S.aLink>
              <S.Link $isblock to='/login'><FormattedMessage id='login' /></S.Link>
              <GoldButton $isblock to='/signup'><FormattedMessage id='signup' /></GoldButton>
            </S.MobileLinksLoggedOut>
          ) : (
            <>
              <S.MobileLinksLoggedIn>
                <GoldButton $isblock to='/buybundle'><FormattedMessage id='getAgriut' /></GoldButton>
                <div style={{ marginBottom: '2.5em' }} />
                <S.Link key='20' $isblock to='/wallet'><FormattedMessage id='wallet' /></S.Link>
                <S.Link key='10' $isblock to='/profile'><FormattedMessage id='seeProfile' /></S.Link>
                { profile?.permissions.find((perm)=>perm==100) &&
                  <>
                    <S.Link key='50' $isblock to='/admin'><FormattedMessage id='admin' /></S.Link>
                  </>
                }
              </S.MobileLinksLoggedIn>
              <S.LogoutMobile>
                <S.Link key='330' $isblock onClick={logout} to='/'><FormattedMessage id='logout' /></S.Link>
              </S.LogoutMobile>
            </>
          )}
        </S.MobileLinksContainer>
      </S.MenuPanel>
    </>
  );
}

export default HomePage;
