import * as S from './styled';

import Left from 'assets/logo-left.svg';
import Right from 'assets/logo-right.svg';

function Logo({ size }: { size: 'small' | 'medium' | 'large' }) {
  return (
    <S.Container $size={size}>
      <S.Part src={Left} />
      <S.Part src={Right} />
    </S.Container>
  );
}

export default Logo;
